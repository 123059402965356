/*
 * @Author: web_liuqunyan
 * @Date: 2022-12-26 14:12:17
 * @LastEditors: 程杭 225435469@qq.com
 * @LastEditTime: 2025-01-13 10:54:41
 * @FilePath: \gggj_main\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/components/Layout';
import store from '../store/index.js';
const defaultSettings = require('../settings.js');
Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'index',
        redirect: '/login'
        // component: Index
    },
    {
        path: '/index',
        name: 'systemindex',
        component: () => import('../views/enterprise.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/retrieval-password',
        name: 'retrieval-password',
        component: () => import('../views/retrieval-password.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/register.vue')
    },
    {
        path: '/transit-page',
        name: 'transitPage',
        component: () => import('../views/transit-page.vue')
    },
    {
        path: '/layout/*',
        component: Layout
    }
];

const createRouter = () =>
    new Router({
        scrollBehavior: () => ({
            y: 0
        }),
        routes: routes,
        mode: 'hash'
    });

const router = createRouter();

router.beforeEach((to, from, next) => {
    // 路由拦截
    const token = store.getters.token === '' ? false : true;
    if (token || defaultSettings.whiteList.find((whitePath) => to.path === whitePath)) {
        next();
    } else {
        next({
            path: '/login'
        });
    }
});

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

export default router;
